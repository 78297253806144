import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { BasicInformation } from '../models/basic-information';
import { Jumbo } from '../models/jumbo';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class BasicInformationService {

  constructor() {
  }

  getInformation(): BasicInformation {
    var result = new BasicInformation(
      "C. Bolivar #249",
      "https://maps.app.goo.gl/6zeQULKjUnD1VFz48",
      "Sucre,Bolivia",
      "6436391",
      "70329077",
      "https://wa.me/59170329077",
      "pastelerialasbuenasmigas",
      "https://www.facebook.com/Pasteler%C3%ADa-Las-Buenas-Migas-100100151779949",
      "@las.buenasmigas", "https://www.instagram.com/las.buenasmigas",
      "http://m.me/100100151779949"
    );
    return result;
  }

  async getJumbos() {
    var jumbosResponse = null;
    try {
      jumbosResponse = await axios.get('https://firestore.googleapis.com/v1/projects/buenas-migas-c15ad/databases/(default)/documents/jumbos');
    } catch (error) {
      console.log(error)
    }
    return jumbosResponse;
  }

  getJumboByType(type) {
    return axios.post('https://firestore.googleapis.com/v1/projects/buenas-migas-c15ad/databases/(default)/documents:runQuery',
      {
        "structuredQuery": {
          "from": [
            {
              "collectionId": "jumbos",
              "allDescendants": true
            }
          ],
          "where": {
            "fieldFilter": {
              "field": {
                "fieldPath": "type"
              },
              "op": "EQUAL",
              "value": {
                "stringValue": type
              }
            }
          }
        }
      });
  }

}
